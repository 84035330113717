<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="backRoute"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      Kaarten
    </h2>
    <div class="tw-my-2">
      <button type="button" class="btn btn-sm btn-primary" @click="toggleMode">
        <i :class="iconClass"></i> {{ mode === 'view' ? 'Afbeeldingen maken' : 'Kaarten raadplegen' }}
      </button>
    </div>
    <div v-show="mode === 'view'">
      <div class="tw-flex tw-gap-x-1">
        <button
          v-for="mapType in Object.keys(mapTypes)"
          :key="mapType"
          type="button"
          :class="[
            { 'tw-text-white tw-bg-success': selectedMapType === mapType },
            'tw-px-2 tw-rounded-full tw-border'
          ]"
          @click="selectedMapType = mapType"
        >
          {{ mapTypes[mapType] }}
        </button>
      </div>
      <EntityLayerMap
        ref="viewMap"
        :location="geolocation"
        :region="region"
        :baseType="selectedMapType === 'locationPlan' ? 'satellite' : 'osm'"
        :mapType="selectedMapType"
      />
    </div>
    <div v-show="mode === 'image'">
      <EntityLayerMapGrid
        ref="imageMaps"
        :geolocation="geolocation"
        :region="region"
        :selectedMapTypes="['regionPlan', 'cadaster', 'waterPolicy', 'waterPolicyAdvice', 'preemption', 'heritage', 'locationPlan']"
        :availableMapTypes="availableMapTypes"
      />
    </div>
  </div>
</template>
<script>
import EntityLayerMap from '@/components/properties/EntityLayerMap'
import EntityLayerMapGrid from '@/components/properties/EntityLayerMapGrid'
import { getProperty, getPropertyGeolocation } from '@/services/properties'
import { getProjectById, getProjectGeolocation } from '@/services/projects'

export default {
  name: 'EntityLayerMapModal',
  components: { EntityLayerMap, EntityLayerMapGrid },
  constants: {
    mapTypes: {
      default: 'Standaard',
      regionPlan: 'Gewestplan',
      cadaster: 'Kadaster',
      waterPolicy: 'Watertoets',
      waterPolicyAdvice: 'Watertoets advieskaart',
      preemption: 'Voorkooprecht',
      heritage: 'Onroerend erfgoed',
      locationPlan: 'Inplantingsplan'
    }
  },
  data () {
    return {
      entity: null,
      geolocation: {},
      mode: 'view',
      selectedMapType: 'default'
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    backRoute () {
      return this.$route.meta.backRoute
    },
    region () {
      if (this.entity && this.entity.region) {
        return this.entity.region === 1 ? 'brussels' : 'flanders'
      }
      return 'flanders'
    },
    iconClass () {
      return {
        view: 'glyphicon glyphicon-save',
        image: 'glyphicon glyphicon-eye-open'
      }[this.mode] || 'glyphicon glyphicon-question-sign' // Default icon
    },
    availableMapTypes () {
      if (this.region === 'flanders') {
        return {
          regionPlan: {
            label: 'Gewestplan',
            zoomConfig: {
              min: 15,
              max: 20,
              default: 16
            }
          },
          cadaster: {
            label: 'Kadaster',
            zoomConfig: {
              min: 17,
              max: 20,
              default: 18
            }
          },
          waterPolicy: {
            label: 'Watertoets',
            zoomConfig: {
              min: 15,
              max: 20,
              default: 16
            }
          },
          waterPolicyAdvice: {
            label: 'Watertoets advieskaart',
            zoomConfig: {
              min: 15,
              max: 20,
              default: 16
            }
          },
          preemption: {
            label: 'Voorkooprecht',
            zoomConfig: {
              min: 15,
              max: 20,
              default: 16
            }
          },
          heritage: {
            label: 'Onroerend erfgoed',
            zoomConfig: {
              min: 16,
              max: 20,
              default: 18
            }
          },
          locationPlan: {
            label: 'Inplantingsplan',
            zoomConfig: {
              min: 17,
              max: 20,
              default: 18
            }
          }
        }
      }
      return {}
    }
  },
  methods: {
    init () {
      const promises = [this.getEntity(), this.getGeoLocation()]
      Promise.all(promises).then(() => {
        this.$nextTick(() => {
          this.$refs.viewMap.render()
        })
      })
    },
    goBack () {
      this.$router.push(this.backRoute)
    },
    async getGeoLocation () {
      const entityId = this.$route.params.id
      let response = null
      if (this.$route.meta.entity_type === 'property') {
        response = await getPropertyGeolocation(entityId)
      } else {
        response = await getProjectGeolocation(entityId)
      }
      this.geolocation = response.data
      return response
    },
    async getEntity () {
      const entityId = this.$route.params.id
      let response = null
      if (this.$route.meta.entity_type === 'property') {
        response = await getProperty(entityId)
      } else {
        response = await getProjectById(entityId)
      }
      this.entity = response.data
      return response
    },
    toggleMode () {
      this.mode = this.mode === 'view' ? 'image' : 'view'
      if (this.mode === 'image') {
        this.$nextTick(() => {
          this.$refs.imageMaps.render()
        })
      }
    }
  }
}
</script>
